import {
  USER_LIST_GET_LIST,
  USER_LIST_GET_LIST_ERROR,
  USER_LIST_GET_LIST_SUCCESS,
  UPDATE_PAGE_SIZE,
  SHOW_SPINNER,
  HIDE_SPINNER,
} from '../actions';

const INIT_STATE = {
  loading: false,
  error: '',
  users: [],
  totalCount: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_LIST_GET_LIST:
      return { ...state, loading: true };

    case USER_LIST_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };

    case USER_LIST_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload };

    case UPDATE_PAGE_SIZE:
      return { ...state, totalCount: action.payload };

    case SHOW_SPINNER:
      return {
        ...state,
        loading: true,
      };
    case HIDE_SPINNER:
      return {
        ...state,
        loading: false,
      };
    default:
      return { ...state };
  }
};
