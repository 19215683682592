import { CHANGE_LOCALE, PASSWORD_VISIBLE, CONFIRM_PASSWORD_VISIBLE } from '../actions';
import { getCurrentLanguage } from '../../helpers/Utils';

const INIT_STATE = {
  locale: getCurrentLanguage(),
  passwordVisible: false,
  confirmPasswordVisible : false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...state, locale: action.payload };
    case PASSWORD_VISIBLE:
      return { ...state, passwordVisible: !state.passwordVisible };
    case CONFIRM_PASSWORD_VISIBLE:
      return { ...state, confirmPasswordVisible: !state.confirmPasswordVisible };

    default:
      return { ...state };
  }
};
