import {
  DOCUMENT_GET_LIST,
  DOCUMENT_GET_LIST_SUCCESS,
  DOCUMENT_SELECTED_MONTH,
  DOCUMENT_SELECTED_REVIEW,
  DOCUMENT_SELECTED_YEAR,
} from '../actions';

const INIT_STATE = {
  loading: false,
  error: '',
  documents: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DOCUMENT_GET_LIST:
      return { ...state, loading: true };
    case DOCUMENT_SELECTED_MONTH:
      return { ...state, month: action.payload };
    case DOCUMENT_SELECTED_YEAR:
      return { ...state, year: action.payload };
    case DOCUMENT_SELECTED_REVIEW:
      return { ...state, review: action.payload };
    case DOCUMENT_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        documents: action.payload,
        month: action.payload?.month,
        year: action.payload?.year,
        review: action.payload?.review,
      };
    default:
      return { ...state };
  }
};
