// eslint-disable-next-line
import {
  USER_LIST_GET_LIST,
  USER_LIST_GET_LIST_SUCCESS,
  USER_LIST_GET_LIST_ERROR,
  USER_LIST_ADD_ITEM,
  USER_LIST_ADD_ITEM_SUCCESS,
  USER_LIST_ADD_ITEM_ERROR,
  UPDATE_PAGE_SIZE,
  SHOW_SPINNER,
  HIDE_SPINNER,
} from '../actions';

export const getUserlist = () => ({
  type: USER_LIST_GET_LIST,
});

export const getUserListSuccess = (users) => ({
  type: USER_LIST_GET_LIST_SUCCESS,
  payload: users,
});

export const updatePage = (totalCount) => ({
  type: UPDATE_PAGE_SIZE,
  payload: totalCount,
});

export const getUSERListError = (error) => ({
  type: USER_LIST_GET_LIST_ERROR,
  payload: error,
});

export const addUSERItem = (item) => ({
  type: USER_LIST_ADD_ITEM,
  payload: item,
});

export const addUSERItemSuccess = (items) => ({
  type: USER_LIST_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addUSERItemError = (error) => ({
  type: USER_LIST_ADD_ITEM_ERROR,
  payload: error,
});

export const showSpinner = () => {
  return {
    type: SHOW_SPINNER,
  };
};
export const hideSpinner = () => {
  return {
    type: HIDE_SPINNER,
  };
};
