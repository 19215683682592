import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import user from './user/reducer';
import document from './document/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  user,
  document,
});

export default reducers;
