
import {
  DOCUMENT_GET_LIST,
  DOCUMENT_GET_LIST_SUCCESS,
  DOCUMENT_SELECTED_MONTH,
  DOCUMENT_SELECTED_REVIEW,
  DOCUMENT_SELECTED_YEAR,
} from '../actions';

export const getDocument = () => ({
  type: DOCUMENT_GET_LIST,
});

export const getDocumentSuccess = (docs) => ({
  type: DOCUMENT_GET_LIST_SUCCESS,
  payload: docs,
});

export const setDcumentSelectedMonth = (month) => ({
  type: DOCUMENT_SELECTED_MONTH,
  payload: month,
});

export const setDcumentSelectedYear = (year) => ({
  type: DOCUMENT_SELECTED_YEAR,
  payload: year,
});

export const setDcumentSelectedReview = (review) => ({
  type: DOCUMENT_SELECTED_REVIEW,
  payload: review,
});
