// eslint-disable-next-line
import { CHANGE_LOCALE, CONFIRM_PASSWORD_VISIBLE, PASSWORD_VISIBLE } from '../actions';
import { setCurrentLanguage } from '../../helpers/Utils';

// eslint-disable-next-line
export const changeLocale = (locale) => {
  setCurrentLanguage(locale);
  return {
    type: CHANGE_LOCALE,
    payload: locale,
  };
};

// eslint-disable-next-line
export const changePasswordvisibility = (visible) => {
  return {
    type: PASSWORD_VISIBLE,
    payload: visible,
  };
};

// eslint-disable-next-line
export const changeConfirmPasswordvisibility = (visible) => {
  return {
    type: CONFIRM_PASSWORD_VISIBLE,
    payload: visible,
  };
};